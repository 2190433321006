import React, { useState, useEffect, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const PricesHistoryComponent = () => {
    const [pricesHistory, setPricesHistory] = useState([]);
    const [marketCapHistory, setMarketCapHistory] = useState([]);
    const [individualSZNBidHistory, setIndividualSZNBidHistory] = useState([]);
    const [individualSZNAskHistory, setIndividualSZNAskHistory] = useState([]);
    const [lastUpdated, setLastUpdated] = useState('');

    const processDataSet = useCallback((dataSet) => {
        // Group the data by date
        const groupedByDate = dataSet.reduce((acc, item) => {
            const date = item.Timestamp.split('T')[0];
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});

        // For each date, find the entry nearest to midday and process the values
        const processedData = Object.entries(groupedByDate).map(([date, entries]) => {
            const middayTime = new Date(date + 'T12:00:00').getTime();
            
            // Find the fetch nearest to midday
            const nearestToMidday = entries.reduce((nearest, entry) => {
                const entryTime = new Date(entry.Timestamp).getTime();
                const currentDiff = Math.abs(entryTime - middayTime);
                const nearestDiff = Math.abs(new Date(nearest.Timestamp).getTime() - middayTime);
                return currentDiff < nearestDiff ? entry : nearest;
            });

            // Group the nearest fetch by SZN_ID
            const groupedByNearestSZN = entries
                .filter(entry => entry.Timestamp === nearestToMidday.Timestamp)
                .reduce((acc, entry) => {
                    acc[entry.SZN_ID] = entry;
                    return acc;
                }, {});

            // Sum values for all SZNs
            let askTotal = 0;
            let bidTotal = 0;
            let marketCapAskTotal = 0;
            let marketCapBidTotal = 0;
            const individualSZNs = {};

            Object.entries(groupedByNearestSZN).forEach(([sznId, sznData]) => {
                askTotal += parseFloat(sznData.AskTotal);
                bidTotal += parseFloat(sznData.BidTotal);
                marketCapAskTotal += parseFloat(sznData.MarketCapAsk);
                marketCapBidTotal += parseFloat(sznData.MarketCapBid);
                individualSZNs[`SZN${sznId}BidTotal`] = parseFloat(sznData.BidTotal);
                individualSZNs[`SZN${sznId}AskTotal`] = parseFloat(sznData.AskTotal);
            });

            return {
                date,
                askTotal: parseFloat(askTotal.toFixed(4)),
                bidTotal: parseFloat(bidTotal.toFixed(4)),
                marketCapAskTotal: parseFloat(marketCapAskTotal.toFixed(2)),
                marketCapBidTotal: parseFloat(marketCapBidTotal.toFixed(2)),
                ...individualSZNs,
                timestamp: nearestToMidday.Timestamp
            };
        });

        return processedData.sort((a, b) => new Date(a.date) - new Date(b.date));
    }, []);

    const processDataForCharts = useCallback((data) => {
        const startDate = '2024-09-18'; // September 18, 2024
        const today = new Date().toISOString().split('T')[0];

        // Process all data for prices and individual SZNs
        const processedData = processDataSet(data);

        // Filter data for market cap and individual SZN bid prices starting from the specified date
        const filteredData = processedData.filter(item => item.date >= startDate);

        return { 
            pricesData: processedData,
            marketCapData: filteredData.filter(item => item.date >= today),
            individualSZNBidData: filteredData,
            individualSZNAskData: processedData
        };
    }, [processDataSet]);

    useEffect(() => {
        fetch('https://stats6529backend-f135373a86cd.herokuapp.com/api/szn_prices_history')
            .then(response => response.json())
            .then(data => {
                console.log("Raw API data:", data);
                
                // Process the data for all charts
                const { pricesData, marketCapData, individualSZNBidData, individualSZNAskData } = processDataForCharts(data.data);
                setPricesHistory(pricesData);
                setMarketCapHistory(marketCapData);
                setIndividualSZNBidHistory(individualSZNBidData);
                setIndividualSZNAskHistory(individualSZNAskData);

                // Set the last updated date
                const lastDataPoint = pricesData[pricesData.length - 1];
                setLastUpdated(lastDataPoint.date);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [processDataForCharts]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label">{`Date: ${label}`}</p>
                    <p className="intro">{`Timestamp: ${payload[0].payload.timestamp}`}</p>
                    {payload.map((pld, index) => (
                        <p key={index} className="desc">{`${pld.name}: ${pld.value}`}</p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const renderIndividualSZNLines = (data, type) => {
        const colors = ['#FF0000', '#0000FF', '#00FF00', '#FF00FF', '#00FFFF', '#FFA500', '#800080', '#008000'];
        const sznKeys = Object.keys(data[0] || {}).filter(key => key.startsWith('SZN') && key.endsWith(`${type}Total`));
        const uniqueSZNs = [...new Set(sznKeys.map(key => key.match(/^SZN(\d+)/)[1]))].sort((a, b) => a - b);

        return uniqueSZNs.map((szn, index) => (
            <Line
                key={`SZN${szn}${type}Total`}
                type="monotone"
                dataKey={`SZN${szn}${type}Total`}
                name={`SZN ${szn} ${type}`}
                stroke={colors[index % colors.length]}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 8 }}
            />
        ));
    };

    const ChartSection = ({ title, children }) => (
        <div style={{
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '20px',
            marginBottom: '40px',
            backgroundColor: '#f8f9fa'
        }}>
            <h2 className="sub-header" style={{ 
                textAlign: 'center', 
                marginBottom: '20px',
                backgroundColor: '#e9ecef',
                padding: '10px',
                borderRadius: '4px'
            }}>{title}</h2>
            {children}
        </div>
    );

    return (
        <div className="prices-history-container" style={{ backgroundColor: '#ffffff', padding: '20px' }}>
            <h1 className="main-header" style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Prices History</h1>
            {lastUpdated && <p className="last-updated" style={{ textAlign: 'center', marginBottom: '30px', color: '#666' }}>Last Updated: {lastUpdated}</p>}
            
            <ChartSection title="Complete Collection Valuation">
                <div className="chart-container" style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={pricesHistory}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Line 
                                type="monotone" 
                                dataKey="askTotal" 
                                name="Ask Total" 
                                stroke="#FF0000" 
                                strokeWidth={3}
                                dot={false}
                                activeDot={{ r: 8 }}
                            />
                            <Line 
                                type="monotone" 
                                dataKey="bidTotal" 
                                name="Bid Total" 
                                stroke="#0000FF" 
                                strokeWidth={3}
                                dot={false}
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </ChartSection>

            <ChartSection title="Market Cap Valuations">
                <div className="chart-container" style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={marketCapHistory}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Line 
                                type="monotone" 
                                dataKey="marketCapAskTotal" 
                                name="Market Cap Ask" 
                                stroke="#FF0000" 
                                strokeWidth={3}
                                dot={false}
                                activeDot={{ r: 8 }}
                            />
                            <Line 
                                type="monotone" 
                                dataKey="marketCapBidTotal" 
                                name="Market Cap Bid" 
                                stroke="#0000FF" 
                                strokeWidth={3}
                                dot={false}
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </ChartSection>

            <ChartSection title="Individual SZN Valuations (Bid Prices)">
                <div className="chart-container" style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={individualSZNBidHistory}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            {renderIndividualSZNLines(individualSZNBidHistory, 'Bid')}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </ChartSection>

            <ChartSection title="Individual SZN Valuations (Ask Prices)">
                <div className="chart-container" style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={individualSZNAskHistory}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            {renderIndividualSZNLines(individualSZNAskHistory, 'Ask')}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </ChartSection>
        </div>
    );
};

export default PricesHistoryComponent;