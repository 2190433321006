import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const TdhStatsComponent = () => {
    const [tableData, setTableData] = useState([]);
    const [lastUpdated, setLastUpdated] = useState('');
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        fetch('https://stats6529backend-f135373a86cd.herokuapp.com/api/tdh_stats')
            .then(response => response.json())
            .then(data => {
                console.log("Raw API data:", data);
                setTableData(data.data || []);

                // Prepare data for the graph
                const dailyData = {};
                (data.data || []).forEach(item => {
                    const date = new Date(item.date).toISOString().split('T')[0];
                    const pptdh = parseFloat(item.pptdh);
                    if (!dailyData[date] || pptdh < dailyData[date].pptdh) {
                        dailyData[date] = { date, pptdh };
                    }
                });
                const graphData = Object.values(dailyData).sort((a, b) => new Date(a.date) - new Date(b.date));
                setGraphData(graphData);

                // Set the last updated date and time using the lastUpdated from the API
                if (data.lastUpdated) {
                    const apiUpdateTime = new Date(data.lastUpdated);
                    
                    // Get the UTC time and the local time
                    const utcTime = apiUpdateTime.toUTCString();
                    const localTime = apiUpdateTime.toLocaleString('en-GB', {
                        timeZone: 'Europe/London',
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    });

                    // Adjust the display to show both UTC and UK time
                    setLastUpdated(`UK Time: ${localTime}, UTC: ${utcTime.split(' ').slice(1, 5).join(' ')}`);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '0 15px' }}>
            <h1 className="main-header" style={{ textAlign: 'center' }}>TDH Stats</h1>
            <h2 className="sub-header">Lowest PPTDH Each Day</h2>
            {lastUpdated && <p className="last-updated">Last Updated: {lastUpdated}</p>}
            <div style={{ overflowX: 'auto' }}>
                <table className="styled-table centered-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Token No</th>
                            <th>Name</th>
                            <th>Meme Name</th>
                            <th>Supply</th>
                            <th>SZN</th>
                            <th>Ask Price</th>
                            <th>TDH Rate</th>
                            <th>PPTDH</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index}>
                                <td>{new Date(row.date).toISOString().split('T')[0]}</td>
                                <td>{row.token_no}</td>
                                <td>{row.name}</td>
                                <td>{row.meme_name}</td>
                                <td>{row.supply}</td>
                                <td>{row.szn}</td>
                                <td>{parseFloat(row.ask_price).toFixed(4)}</td>
                                <td>{parseFloat(row.tdh_rate).toFixed(4)}</td>
                                <td>{parseFloat(row.pptdh).toFixed(4)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <h2 className="sub-header">PPTDH Over Time</h2>
            <div style={{ width: '100%', height: '400px', maxWidth: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
                <div style={{ width: '100%', minWidth: '600px', height: '100%' }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={graphData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="pptdh" stroke="red" strokeWidth={4} activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default TdhStatsComponent;