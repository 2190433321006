import React, { useEffect, useState } from 'react';
import { fetchPrices, fetchSznPrices } from './apiService';
import './App.css';

const PricesComponent = () => {
    const [prices, setPrices] = useState([]);
    const [sznPrices, setSznPrices] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [latestTimestamp, setLatestTimestamp] = useState('N/A');

    useEffect(() => {
        const getPrices = async () => {
            try {
                const response = await fetchPrices();
                console.log('API Response:', response.data);
                setPrices(response.data);

                if (response.data.length > 0) {
                    const latestTime = new Date(Math.max(...response.data.map(item => new Date(item.Timestamp))));
                    
                    // Convert to UK time and UTC
                    const ukTime = latestTime.toLocaleString('en-GB', {
                        timeZone: 'Europe/London',
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    });
                    const utcTime = latestTime.toUTCString().split(' ').slice(1, 5).join(' ');

                    setLatestTimestamp(`UK Time: ${ukTime}, UTC: ${utcTime}`);
                }
            } catch (error) {
                console.error('Error fetching prices:', error);
            }
        };

        const getSznPrices = async () => {
            try {
                const response = await fetchSznPrices();
                console.log('SZN Prices API Response:', response.data);
                setSznPrices(response.data);
            } catch (error) {
                console.error('Error fetching SZN prices:', error);
            }
        };

        getPrices();
        getSznPrices();
    }, []);

    const sortTable = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedPrices = [...prices].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setPrices(sortedPrices);
    };

    const getSortClass = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? 'sorted-asc' : 'sorted-desc';
        }
        return 'sortable-column';
    };

    const calculateTotalSznValues = () => {
        const totalAsk = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.AskTotal) || 0), 0);
        const totalBid = sznPrices.reduce((sum, szn) => {
            const bidTotal = parseFloat(szn.BidTotal);
            return isNaN(bidTotal) ? sum : sum + bidTotal;
        }, 0);
        const totalMarketCapAsk = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.MarketCapAsk) || 0), 0);
        const totalMarketCapBid = sznPrices.reduce((sum, szn) => sum + (parseFloat(szn.MarketCapBid) || 0), 0);
        return { totalAsk, totalBid, totalMarketCapAsk, totalMarketCapBid };
    };

    return (
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
            <h1 className="main-header">Unofficial 6529 Stats</h1>
            <h2 className="sub-header">6529 Memes NFT Collection Stats</h2>
            <div style={{ textAlign: 'left', marginLeft: '20px' }}>
                <strong>Last Updated: </strong> {latestTimestamp}
            </div>

            <div style={{ marginBottom: '20px' }}></div>

            <div style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '20px' }}>
                <table className="styled-table centered-table">
                    <thead>
                        <tr>
                            <th colSpan="5" className="table-title">SZN Valuations</th>
                        </tr>
                        <tr>
                            <th>SZN ID</th>
                            <th>Ask Total</th>
                            <th>Bid Total</th>
                            <th>Market Cap (Ask)</th>
                            <th>Market Cap (Bid)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sznPrices.map((szn, index) => (
                            <tr key={index}>
                                <td>{szn.SZN_ID}</td>
                                <td>{szn.AskTotal}</td>
                                <td>{isNaN(parseFloat(szn.BidTotal)) ? '' : szn.BidTotal}</td>
                                <td>{szn.MarketCapAsk}</td>
                                <td>{szn.MarketCapBid}</td>
                            </tr>
                        ))}
                        <tr style={{ fontWeight: 'bold' }}>
                            <td>All SZNs</td>
                            <td>{calculateTotalSznValues().totalAsk.toFixed(2)}</td>
                            <td>{calculateTotalSznValues().totalBid > 0 ? calculateTotalSznValues().totalBid.toFixed(2) : ''}</td>
                            <td>{calculateTotalSznValues().totalMarketCapAsk.toFixed(2)}</td>
                            <td>{calculateTotalSznValues().totalMarketCapBid.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={{ marginBottom: '40px' }}></div>

            <table className="centered-table">
                <thead>
                    <tr>
                        <th className={`cursor-pointer ${getSortClass('ID')}`} onClick={() => sortTable('ID')}>Token<br />No<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('Name')}`} onClick={() => sortTable('Name')}>Name<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('MemeName')}`} onClick={() => sortTable('MemeName')}>Meme<br />Name<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('Supply')}`} onClick={() => sortTable('Supply')}>Supply<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('TypeSeason')}`} onClick={() => sortTable('TypeSeason')}>SZN<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('FloorPrice')}`} onClick={() => sortTable('FloorPrice')}>Ask<br />Price<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('MarketCapAsk')}`} onClick={() => sortTable('MarketCapAsk')}>Market Cap<br />(Ask Prices)<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('HighestBid')}`} onClick={() => sortTable('HighestBid')}>Top<br />Bid<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('MarketCapBid')}`} onClick={() => sortTable('MarketCapBid')}>Market Cap<br />(Bid Prices)<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('HodlRate')}`} onClick={() => sortTable('HodlRate')}>TDH<br />Rate<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('PPTDH')}`} onClick={() => sortTable('PPTDH')}>PPTDH<span className="sort-icon"></span></th>
                        <th className={`cursor-pointer ${getSortClass('Timestamp')}`} onClick={() => sortTable('Timestamp')}>Last<br />Updated<span className="sort-icon"></span></th>
                    </tr>
                </thead>
                <tbody>
                    {prices.map((price, index) => (
                        <tr key={index}>
                            <td>{price.ID}</td>
                            <td>{price.Name}</td>
                            <td>{price.MemeName}</td>
                            <td>{price.Supply}</td>
                            <td>{price.TypeSeason}</td>
                            <td>{price.FloorPrice}</td>
                            <td>{price.MarketCapAsk}</td>
                            <td>{price.HighestBid}</td>
                            <td>{price.MarketCapBid}</td>
                            <td>{price.HodlRate}</td>
                            <td>{price.PPTDH}</td>
                            <td>{new Date(price.Timestamp).toLocaleString('en-GB', {
                                timeZone: 'Europe/London',
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            })}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PricesComponent;