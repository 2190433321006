import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PricesComponent from './PricesComponent';
import TdhStatsComponent from './TdhStatsComponent';
import PricesHistoryComponent from './PricesHistoryComponent';

function App() {
    return (
        <Router>
            <div className="App">
                <nav>
                    <ul className="menu">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/tdh-stats">TDH Stats</Link>
                        </li>
                        <li>
                            <Link to="/prices-history">Prices History</Link>
                        </li>
                    </ul>
                </nav>

                <Routes>
                    <Route path="/" element={<PricesComponent />} />
                    <Route path="/tdh-stats" element={<TdhStatsComponent />} />
                    <Route path="/prices-history" element={<PricesHistoryComponent />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;