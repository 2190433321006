const API_BASE_URL = "https://stats6529backend-f135373a86cd.herokuapp.com/api";

export const fetchPrices = async () => {
    const response = await fetch(`${API_BASE_URL}/prices`);
    return await response.json();
};

export const fetchSznPrices = async () => {
    const response = await fetch(`${API_BASE_URL}/szn_prices`);
    return await response.json();
};

export const fetchSznPricesHistory = async () => {
    const response = await fetch(`${API_BASE_URL}/szn_prices_history`);
    return await response.json();
};
